.track{	
	margin: 0;
    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-size: .875rem;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fafafa;
}

.track{
    font-weight: 400;
}


button{
	outline: 0 !important;
}
